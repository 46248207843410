import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Collapse,
  NavbarToggler,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Search,
  Input,
} from "reactstrap";




import {
  Box,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  ListItemButton,
  Divider,
  Avatar
} from "@mui/material";


import { setName, setUName, setEmail, setProfile, setIsLogged, setUid, setAgencyName, setloggedate } from "../../Redux/UserSlice";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";


import userImage2 from "../../assets/images/img-03.jpg";

import { base_url } from "../../apis/constants";
// import { getCurrentUserData } from "../../apis/User";
import { BsSearch } from "react-icons/bs";
const NavBar = ({ drawerWidth, backgroundColor }) => {


  const loggedate = useSelector((state) => state.CMSAgencyUser.loggedate);
  const uName = useSelector((state) => state.CMSAgencyUser.name);
  const uProfile = useSelector((state) => state.CMSAgencyUser.profile);
  const uAgencyName = useSelector((state) => state.CMSAgencyUser.agencyName);
  const dispatch = useDispatch();
  const history = useHistory();
  const Role = localStorage.getItem("Role");
  const approvalStatus = localStorage.getItem("ApprovalStatus");



  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);


  //Notification Dropdown
  const [notification, setNotification] = useState(false);
  const dropDownnotification = () => setNotification((prevState) => !prevState);

  const [cart, setCart] = useState(false);
  const cartDropDown = () => setCart((prevState) => !prevState);

  const [grid, setGrid] = useState(false);
  const gridDropDown = () => setGrid((prevState) => !prevState);

  //user Profile Dropdown
  const [userProfile, setUserProfile] = useState(false);
  const dropDownuserprofile = () => setUserProfile((prevState) => !prevState);

  //scroll navbar
  const [navClass, setnavClass] = useState(false);

  const [navigate, setNavigate] = useState("");
  const [user, setUser] = useState();

  setTimeout(() => {
    const Home = localStorage.getItem("Home");
    setNavigate(Home);
  }, 3000);



  // useEffect(() => {
  //   window.addEventListener("scroll", scrollNavigation, true);
  // });


  // useEffect(() => {
  //   getCurrentUserData(Id).then((resp) => {
  //     setUser(resp.data);
  //   });
  // });

  function scrollNavigation() {
    var scrollup = window.pageYOffset;
    if (scrollup > 0) {
      setnavClass("nav-sticky");
    } else {
      setnavClass("");
    }
  }

  // useEffect(() => {
  //   if(loggedate !== '03052024'){
  //     handleLogout();
  //   }
  // }, [])

  const handleLogout = () => {
    dispatch(setUName(''));
    dispatch(setUid(''));
    dispatch(setEmail(''));
    dispatch(setProfile(''));
    dispatch(setAgencyName(''));
    dispatch(setName(''));
    dispatch(setIsLogged(false));
    dispatch(setloggedate(""));
    history.push("/signin");
  };

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement.parentElement.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }




  const currentScreenText = {
    '/': 'Dashboard',
    '/my-package': 'My Package',
    '/my-profile': 'My Profile',

    '/dashboard': 'Dashboard',

    // '/addons': 'Addons',

    '/bookings': 'Bookings',

    '/users': 'Users',
    '/register-user': 'Register User',

    '/tours': 'Tours',
    '/register-tour': 'Register Tour',

    '/tickets': 'Group Tickets',
    '/register-ticket': 'Register Ticket',
    
    '/hajj': 'Hajj',
    '/register-hajj': 'Register Hajj',

    // '/hotels': 'Hotels',
    // '/register-hotel': 'Register Hotel',

    '/transports': 'Transports',
    '/register-transport': 'Register Transport',

    '/visas': 'Visas',
    '/register-visa': 'Register Visa',

  };


  const currentScreen = useLocation();

  return (
    <AppBar
      position="fixed"
      sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, background: backgroundColor, color: 'black', border: 0, boxShadow: 0 }}
    >
      <Toolbar className="d-flex flex-row justify-content-between">

        <Typography variant='h6' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
          {currentScreenText[currentScreen.pathname]}
        </Typography>


        <ul className="header-menu list-inline d-flex align-items-center mb-0">

          <Typography variant='h6' style={{ color: 'rgba(0, 0, 0, 0.87)' }} className="mx-3 text-primary">
            {uAgencyName}
          </Typography>


          <Dropdown
            onClick={() => setUserProfile(!userProfile)}
            isOpen={userProfile}
            toggle={dropDownuserprofile}
            className="list-inline-item"
          >
            <DropdownToggle
              to="#"
              className="header-item"
              id="userdropdown"
              type="button"
              tag="a"
              aria-expanded="false"
            >
              {
                uProfile ?
                  <img
                    src={`${base_url}${uProfile}`}
                    alt="mdo"
                    width="35"
                    height="35"
                    className="rounded me-1"
                  />
                  :
                  <Avatar className="me-1 mt-3 rounded" sx={{ height: 35, width: 35 }} >{uName.split(' ')[0]?.[0]?.toUpperCase()}{uName.split(' ')[1]?.[0]?.toUpperCase()}</Avatar>
              }

              {" "}
              <span className="d-none d-md-inline-block fw-medium">
                {/* {user?.firstName ? (
                     <>Hi, {user?.firstName}</>
                   ) : (
                     <>{"Update Profile"}</>
                   )} */}
              </span>
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu-end"
              aria-labelledby="userdropdown"
              end
            >
              <li>
                <Link className="dropdown-item" to="/my-profile">
                  My Profile
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/my-package">
                  My Package
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  onClick={handleLogout}
                  to="/signin"
                >
                  Logout
                </Link>
                {/* to="/signout" */}
              </li>
            </DropdownMenu>
          </Dropdown>
        </ul>

      </Toolbar>
    </AppBar>
  );
};

export default withRouter(NavBar);





// {/* <nav
//         className={
//           "navbar navbar-expand-lg fixed-top sticky p-0  aa" + navClass
//         }
//         style={{ marginTop: "0px", color: "red" }}
//         id="navigation"
//       >
//         <Container fluid className="custom-container">
//           <Link
//             className="navbar-brand text-dark fw-bold me-auto"
//             to={localStorage.getItem("Home")}
//           >
//             {/* <img src={darkLogo} height="22" alt="" className="logo-dark" />
//                 <img
//                   src={lightLogo}
//                   height="22"
//                   alt=""
//                   className="logo-light"
//                 /> */}
//           </Link>
//           <div>
//             <NavbarToggler
//               className="me-3"
//               type="button"
//               onClick={() => toggle()}
//             >
//               <i className="mdi mdi-menu"></i>
//             </NavbarToggler>
//           </div>
//           <h3>Agency Portal | CMS Travels</h3>
//           <Collapse
//             isOpen={isOpen}
//             className="navbar-collapse"
//             id="navbarCollapse"
//           >
//             <Input
//               placeholder="Search"
//               style={{
//                 width: 350,
//                 marginLeft: 20,
//                 borderRadius: 20,
//                 height: 35,
//               }}
//             >
//               {/* <BsSearch /> */}
//             </Input>
//           </Collapse>

//           <ul className="header-menu list-inline d-flex align-items-center mb-0">
//             <Dropdown
//               isOpen={cart}
//               toggle={cartDropDown}
//               className="list-inline-item">
//               <DropdownToggle
//                 // href="#"
//                 className="header-item noti-icon position-relative"
//                 id="notification"
//                 type="button"
//                 tag="a"
//               >
//                 <span
//                   style={{ marginRight: 10 }}
//                   class="mdi mdi-cart fs-22"
//                 ></span>
//               </DropdownToggle>
//             </Dropdown>
//             <Dropdown
//               isOpen={grid}
//               toggle={gridDropDown}
//               className="list-inline-item">
//               <DropdownToggle
//                 // href="#"
//                 className="header-item noti-icon position-relative"
//                 id="notification"
//                 type="button"
//                 tag="a"
//               >

//                 <span class="mdi mdi-grid fs-22"></span>
//               </DropdownToggle>
//             </Dropdown>
//             <Dropdown
//               isOpen={notification}
//               toggle={dropDownnotification}
//               className="list-inline-item  me-4"
//             >
//               <DropdownToggle
//                 href="#"
//                 className="header-item noti-icon position-relative"
//                 id="notification"
//                 type="button"
//                 tag="a"
//               >
//                 {/* <div className="count position-absolute">23</div> */}
//                 <i className="mdi mdi-bell fs-22"></i>
//                 <div className="count position-absolute">3</div>
//               </DropdownToggle>

//               <DropdownMenu
//                 className="dropdown-menu-sm dropdown-menu-end p-0"
//                 aria-labelledby="notification"
//                 end
//               >
//                 <div className="notification-header border-bottom bg-light">
//                   <h6 className="mb-1"> Notification </h6>
//                   <p className="text-muted fs-13 mb-0">
//                     You have 4 unread Notification
//                   </p>
//                 </div>
//                 <div className="notification-wrapper dropdown-scroll">
//                   <Link
//                     to="#"
//                     className="text-dark notification-item d-block active"
//                   >
//                     <div className="d-flex align-items-center">
//                       <div className="flex-shrink-0 me-3">
//                         <div className="avatar-xs bg-primary text-white rounded-circle text-center">
//                           <i className="uil uil-user-check"></i>
//                         </div>
//                       </div>
//                       <div className="flex-grow-1">
//                         <h6 className="mt-0 mb-1 fs-14">
//                           22 verified registrations
//                         </h6>
//                         <p className="mb-0 fs-12 text-muted">
//                           <i className="mdi mdi-clock-outline"></i>{" "}
//                           <span>3 min ago</span>
//                         </p>
//                       </div>
//                     </div>
//                   </Link>
//                   <Link to="#" className="text-dark notification-item d-block">
//                     <div className="d-flex align-items-center">
//                       <div className="flex-shrink-0 me-3">
//                         <img
//                           src={userImage2}
//                           className="rounded-circle avatar-xs"
//                           alt="user-pic"
//                         />
//                       </div>
//                       <div className="flex-grow-1">
//                         <h6 className="mt-0 mb-1 fs-14">James Lemire</h6>
//                         <p className="text-muted fs-12 mb-0">
//                           <i className="mdi mdi-clock-outline"></i>{" "}
//                           <span>15 min ago</span>
//                         </p>
//                       </div>
//                     </div>
//                   </Link>
//                   <Link to="#" className="text-dark notification-item d-block">
//                     <div className="d-flex align-items-center">
//                       <div className="flex-shrink-0 me-3">
//                         <img
//                           src={userImage2}
//                           className="rounded-circle avatar-xs"
//                           alt="user-pic"
//                         />
//                       </div>
//                       <div className="flex-grow-1">
//                         <h6 className="mt-0 mb-1 fs-14">
//                           Applications has been approved
//                         </h6>
//                         <p className="text-muted mb-0 fs-12">
//                           <i className="mdi mdi-clock-outline"></i>{" "}
//                           <span>45 min ago</span>
//                         </p>
//                       </div>
//                     </div>
//                   </Link>
//                   <Link to="#" className="text-dark notification-item d-block">
//                     <div className="d-flex align-items-center">
//                       <div className="flex-shrink-0 me-3">
//                         <img
//                           src={userImage2}
//                           className="rounded-circle avatar-xs"
//                           alt="user-pic"
//                         />
//                       </div>
//                       <div className="flex-grow-1">
//                         <h6 className="mt-0 mb-1 fs-14">Kevin Stewart</h6>
//                         <p className="text-muted mb-0 fs-12">
//                           <i className="mdi mdi-clock-outline"></i>{" "}
//                           <span>1 hour ago</span>
//                         </p>
//                       </div>
//                     </div>
//                   </Link>
//                   <Link to="#" className="text-dark notification-item d-block">
//                     <div className="d-flex align-items-center">
//                       <div className="flex-shrink-0 me-3">
//                         <img
//                           src={userImage2}
//                           className="rounded-circle avatar-xs"
//                           alt="user-pic"
//                         />
//                       </div>
//                       <div className="flex-grow-1">
//                         <h6 className="mt-0 mb-1 fs-15">Creative Agency</h6>
//                         <p className="text-muted mb-0 fs-12">
//                           <i className="mdi mdi-clock-outline"></i>{" "}
//                           <span>2 hour ago</span>
//                         </p>
//                       </div>
//                     </div>
//                   </Link>
//                 </div>
//                 <div className="notification-footer border-top text-center">
//                   <Link className="primary-link fs-13" to="#">
//                     <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
//                     <span>View More..</span>
//                   </Link>
//                 </div>
//               </DropdownMenu>
//             </Dropdown>

//             <Dropdown
//               onClick={() => setUserProfile(!userProfile)}
//               isOpen={userProfile}
//               toggle={dropDownuserprofile}
//               className="list-inline-item"
//             >
//               <DropdownToggle
//                 to="#"
//                 className="header-item"
//                 id="userdropdown"
//                 type="button"
//                 tag="a"
//                 aria-expanded="false"
//               >
//                 <img
//                   src={userImage2}
//                   alt="mdo"
//                   width="27"
//                   height="27"
//                   className="rounded-circle me-1"
//                 />{" "}
//                 <span className="d-none d-md-inline-block fw-medium">
//                   {/* {user?.firstName ? (
//                     <>Hi, {user?.firstName}</>
//                   ) : (
//                     <>{"Update Profile"}</>
//                   )} */}
//                 </span>
//               </DropdownToggle>
//               <DropdownMenu
//                 className="dropdown-menu-end"
//                 aria-labelledby="userdropdown"
//                 end
//               >
//                 <li>
//                   <Link className="dropdown-item" to="/">
//                     get status
//                   </Link>
//                 </li>
//                 <li>
//                   <Link className="dropdown-item" to="/">
//                     settings
//                   </Link>
//                 </li>
//                 <li>
//                   <Link className="dropdown-item" to="">
//                     My Profile
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     className="dropdown-item"
//                     onClick={handleLogout}
//                     to="/signin"
//                   >
//                     Logout
//                   </Link>
//                   {/* to="/signout" */}
//                 </li>
//               </DropdownMenu>
//             </Dropdown>
//           </ul>
//         </Container>
//       </nav> */}