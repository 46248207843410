import React from "react";


import {
  Container,
  Grid,
  Typography,
  TextField,
  Input,
  FormControl,
  InputAdornment,
  FormHelperText,
  Checkbox,
  InputLabel,
  Button,
  IconButton,
  FormControlLabel,
  CircularProgress
} from "@mui/material";

import './SignIn.css';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import appLogo from '../../assets/images/app-logo-1.png';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';


import MetaTags from "react-meta-tags";


import { Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { AgencyLogin } from "../../apis/agency";
import { TheTailSpinner } from "../../components/Spinners";
import { successToast } from "../../components/Toasts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch } from "react-redux";
import { setName, setUName, setEmail, setProfile, setIsLogged, setUid, setAgencyName, setAgencyID, setloggedate } from "../../Redux/UserSlice";

const SignIn = () => {
  const dispatch = useDispatch();

  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [isMailError, setIsMailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  let history = useHistory();


  const successResponse = succRes => {
    if (succRes.status === 200) {
      dispatch(setUName(succRes?.data?.data?.user?.email))
      dispatch(setUid(succRes?.data?.data?.user?.uid))
      dispatch(setEmail(succRes?.data?.data?.user?.email))
      dispatch(setProfile(succRes?.data?.data?.user?.profile))
      dispatch(setName(`${succRes?.data?.data?.user?.first_name} ${succRes?.data?.data?.user?.last_name}`))
      dispatch(setIsLogged(true));
      dispatch(setloggedate('03052024'));
      dispatch(setAgencyID(succRes?.data?.data?.agency?.id));
      dispatch(setAgencyName(`${succRes?.data?.data?.agency?.first_name}`));
      setIsLoading(false);
      history.push('/dashboard');
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    setIsMailError(false);
    setMailError('');
    setIsPasswordError(false);
    setPasswordError('');
    setIsLoading(true);

    AgencyLogin(userName.trim(), password)
      .then(res => successResponse(res))
      .catch(err => {
        console.log('AddminLoginErr', err);
        setIsLoading(false);
        if (err?.response?.data?.error_messages[0].toLowerCase().includes('user') ||err?.response?.data?.error_messages[0].toLowerCase().includes('email')) {
          setIsMailError(true);
          setMailError(err?.response?.data?.error_messages[0]);
        }
        else {
          setIsPasswordError(true);
          setPasswordError(err?.response?.data?.error_messages[0]);
        }

      });
  };

  return (
    <React.Fragment>
      <ToastContainer
        position='top-center'
        autoClose={4800}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <div className=''>
          <div className=''>
            <MetaTags>
              <title>Sign In | CMS Travel</title>
            </MetaTags>
            <section
              className='bg-auth'
              style={{ backgroundColor: '#ECEFF1' }}
            >

              <Container >

                <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={12} sm={7} md={5} lg={4} xl={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Link to="/">
                      <img src={appLogo} height={150} width={200} />
                    </Link>
                    <div class="login-form">
                      <div class="w-100">
                        <Typography variant="h4" class="login-welcome">
                          Welcome Back
                        </Typography>
                      </div>
                      <Typography variant='caption' class="login-info">
                        Enter your login information
                      </Typography>


                      <Form className='auth-form' fullWidth style={{ width: '100%' }}>
                        <div className='py-1'>
                          <TextField fullWidth
                            error={isMailError}
                            id='username'
                            value={userName}
                            onChange={e => setUserName(e.target.value)}
                            label="Email" variant="standard" required
                            helperText={mailError}
                          />
                        </div>
                        <div className='py-1'>
                          <FormControl fullWidth variant="standard" required

                            error={isPasswordError}
                          >
                            <InputLabel htmlFor="passwordInput">Password</InputLabel>
                            <Input
                              id='passwordInput' fullWidth
                              type={showPassword ? 'text' : 'password'}
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />

                            <FormHelperText>{passwordError}</FormHelperText>
                          </FormControl>
                        </div>

                        {/* <div className='py-1'>
                          <FormControlLabel
                            control={
                              <Checkbox />
                            }
                            label="Remember me"
                          />
                        </div> */}


                        <div className='py-2'>
                          <Button
                            fullWidth
                            disabled={
                              userName && password ? false : true
                            }
                            type='submit'
                            variant='contained'
                            onClick={e => handleSubmit(e)}
                          >
                            <div className="w-100 d-flex" style={{position: 'relative', justifyContent: 'center', alignItems: 'center'}}>
                              <span className="mx-2">
                                  Login
                              </span>

                                {isLoading &&
                                  <CircularProgress  size={18} style={{color: 'white', position: 'absolute', right: 0}} />
                                }
                              
                            </div>
                          </Button>
                        </div>

                        <div style={{ width: '100%', textAlign: 'end', display: 'flex', justifyContent: 'space-between' }} className='py-1'>
                          <Link to='/signup' style={{ color: '#3361FF', fontSize: 13 }}>New Agency? Signup</Link>
                          <Link to='/forget-password' style={{ color: '#3361FF', fontSize: 13 }}>Forget your password?</Link>
                        </div>
                      </Form>

                    </div>

                  </Grid>
                </Grid>
              </Container>

              <div style={{ position: 'fixed', bottom: '8px', }} className='w-100 px-3'>

                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Grid item md={5} lg={5} class="login-footer-cols" style={{ display: 'flex' }}>
                    <div class='login-footer-notes ' >
                      <span class="login-footer-notes">
                        Read our
                      </span>
                      <a href='#' class="login-footer-link"> terms </a>
                      <span class="login-footer-notes">and </span>
                      <a href='#' class="login-footer-link">conditions</a>
                    </div>
                  </Grid>
                  <Grid item md={6} lg={5} class="login-footer-cols" style={{ display: 'flex', justifyContent: 'end' }}>
                    <div class='login-footer-notes'>
                      Need Help?
                      <span class="login-footer-link1 mx-2">
                        <PhoneAndroidOutlinedIcon
                          style={{
                            height: 16,
                            width: 18
                          }}
                        />
                        <a class="login-footer-link1 "
                          href='tel:+1 120 279 53213'
                        >
                          +92 320 44 00 805
                        </a>
                      </span>
                      <span class="login-footer-link1 mx-2">
                        <div class="d-inline-block">
                          <EmailOutlinedIcon
                            style={{
                              height: 16,
                              width: 18
                            }}
                          />
                          <a class="login-footer-link1"
                            href={
                              'mailto:' + 'info@cmstravel.pk'
                            }
                          >
                            info@cmstravel.pk
                          </a>
                        </div>
                      </span>
                    </div>

                  </Grid>
                </Grid>

              </div>

            </section>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
