import React from "react";
/** Add Route Component */
//Home Section

const SignIn = React.lazy(() => import("../pages/ExtraPages/SignIn"));
const SignUp = React.lazy(() => import("../pages/ExtraPages/SignUp"));
const SignOut = React.lazy(() => import("../pages/ExtraPages/SignOut"));
const UpdatePassword = React.lazy(() => import("../pages/ExtraPages/UpdatePassword"));
const ConfirmationCode = React.lazy(() => import("../pages/ExtraPages/ConfirmationCode"));
const ResetPassword = React.lazy(() => import("../pages/ExtraPages/ResetPassword"));
const Error404 = React.lazy(() => import("../pages/ExtraPages/Error404"));


const MyPackages = React.lazy(() => import("../pages/Profile/MyProfile/MyPackages"));
const MyProfile = React.lazy(() => import("../pages/Profile/MyProfile/MyProfile"));


const Layout2 = React.lazy(() => import("../pages/Home/Layout2/Layout2"));
const Addons = React.lazy(() => import("../pages/Addons/Addons"));
const Users = React.lazy(() => import("../pages/Users/Users"));
const RegisterUser = React.lazy(() => import("../pages/Users/RegisterUser"));
const Bookings = React.lazy(() => import("../pages/Bookings/Bookings"));
const Tours = React.lazy(() => import("../pages/Tours/Tours"));
const RegisterTour = React.lazy(() => import("../pages/Tours/RegisterTour"));

const Hajj = React.lazy(() => import("../pages/Hajj/Hajj"));
const RegisterHajj = React.lazy(() => import("../pages/Hajj/RegisterHajj"));

const Tickets = React.lazy(() => import("../pages/Tickets/Tickets"));
const RegisterTicket = React.lazy(() => import("../pages/Tickets/RegisterTicket"));

const Visas = React.lazy(() => import("../pages/Visas/Visas"));
const RegisterVisa = React.lazy(() => import("../pages/Visas/RegisterVisa"));
const Hotels = React.lazy(() => import("../pages/Hotels/Hotels"));
const RegisterHotel = React.lazy(() => import("../pages/Hotels/RegisterHotel"));
const Transports = React.lazy(() => import("../pages/Tranasports/Transports"));
const RegisterTransport = React.lazy(() => import("../pages/Tranasports/RegisterTransport"));

const SignIng = () => {

  return (
    <div className="w-100 h-100 border border-danger border-2 d-flex flex-row flex-wrap" style={{transform: 'rotate(-20deg)'}}>

      {[...Array(400)].map((_, index) => (
        <p key={index} style={{ fontSize: 15, fontWeight: 'bold', color: 'rgba(0, 109, 67, 0.7)', lineHeight: '20px', marginRight: 10, marginLeft: 10, marginTop: 5, marginBottom: 5 }} >
          cmstravel.pk
        </p>
      ))}

    </div>
  )
}


const userRoutes = [
  { path: "/", component: Layout2 },
  { path: "/my-package", component: MyPackages },
  { path: "/my-profile", component: MyProfile },

  { path: "/dashboard", component: Layout2 },


  // { path: "/addons", component: Addons },

  { path: "/users", component: Users },
  { path: "/register-user", component: RegisterUser },

  { path: "/bookings", component: Bookings },

  { path: "/tours", component: Tours },
  { path: "/register-tour", component: RegisterTour },

  { path: "/tickets", component: Tickets },
  { path: "/register-ticket", component: RegisterTicket },

  { path: "/hajj", component: Hajj },
  { path: "/register-hajj", component: RegisterHajj },

  { path: "/visas", component: Visas },
  { path: "/register-visa", component: RegisterVisa },

  { path: "/hotels", component: Hotels },
  { path: "/register-hotel", component: RegisterHotel },

  { path: "/transports", component: Transports },
  { path: "/register-transport", component: RegisterTransport },

  // { path: "*", component: Error404 },
];

const authRoutes = [
  { path: "/reset-password", component: UpdatePassword },
  { path: "/signin", component: SignIn },
  // { path: "/signing", component: SignIng },
  { path: "/signup", component: SignUp },
  { path: '/forget-password', component: ResetPassword },


  // { path: "/signout", component: SignOut },
  // { path: "/signup", component: SignUp },
  // { path: "/confirmation", component: ConfirmationCode },
  // { path: "*", component: Error404 },
];
export { userRoutes, authRoutes };
