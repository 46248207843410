import React, { Suspense } from "react";

//Importing Section
// const TopBar = import("../CommonLayout/TopBar");
import TopBar from "../CommonLayout/TopBar";
import NavBar from "../CommonLayout/NavBar";
import Subscribe from "../CommonLayout/Subscribe";
import Footer from "../CommonLayout/Footer";
import StyleSwitcher from "../CommonLayout/StyleSwitcher";
import ScrolltoTop from "../../components/ScrolltoTop";
import SignIn from "../../pages/ExtraPages/SignIn";
import { useSelector } from "react-redux";


import {
  Box,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Drawer,
  ListItemButton,
  Divider
} from "@mui/material";


import CssBaseline from '@mui/material/CssBaseline';
import SideBar from './SideBar';
import './index.css';
import { MetaTags } from "react-meta-tags";


const Layout = (props) => {
  const loggedate = useSelector(state => state.CMSAgencyUser.loggedate);
  const uName = useSelector(state => state.CMSAgencyUser.uname);
  const isLogged = useSelector(state => state.CMSAgencyUser.isLogged);

  const drawerWidth = 90;
  const backgroundColor = '#F5F5F5';
  return (
    <React.Fragment>
      <MetaTags>
        <title>Agency Portal | CMS Travels</title>
      </MetaTags>
      <Suspense>
        <div>
          {
            (uName && isLogged && loggedate === '03052024') ?
              <>

                <Box sx={{ display: 'flex', background: backgroundColor, height: '100%', maxHeight: '100%' }}>
                  <CssBaseline />
                  <NavBar drawerWidth={drawerWidth} backgroundColor={backgroundColor} />
                  <SideBar drawerWidth={drawerWidth} />
                  <Box
                    component="main"
                    className="p-0"
                    sx={{ flexGrow: 1, maxHeight: '100%' }}
                  >
                    <Toolbar />
                    <Box
                      component="main"
                      className="main-box w-100 m-0"
                      sx={{ px: 3, py: 1, background: backgroundColor, maxHeight: 'calc(100% - 65px)', overflowY: 'scroll', maxWidth: '93vw'  }}
                    >
                      {props.children}
                    </Box>
                  </Box>
                </Box>

              </>
              :
              <SignIn />
          }

        </div>
      </Suspense>
    </React.Fragment>
  );
};

export default Layout;














// <div>
//   {/* <TopBar /> */}
//   {uName && isLogged && <NavBar />}
//   <div className="main-content">
//     <div className="page-content">
//       {(uName && isLogged) ? props.children : <SignIn />}
//     </div>
//   </div>
//   {/* <StyleSwitcher /> */}
//   {/* {UserId && (
//             <>
//             <h1 style={{padding:100}}>CMS | Dashboard</h1>
//               <Subscribe />
//               <ScrolltoTop />
//               <Footer />
//             </>
//           )} */}
// </div>